<template>


  <SkBasicForm 
    v-if="is_loading"
    />

  <div v-else-if="data_profile?.id">
    

 
    <div class="modal_user_content">

      <div class="image content content_profile">
  
          

        <div class="settings_description">
  

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_fb') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="data_profile.link_facebook" placeholder="facebook.com/...">
              <i class="icon facebook"></i>
            </div>
          </div>

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_yt') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="data_profile.link_youtube" placeholder="youtube.com/...">
              <i class="icon facebook"></i>
            </div>
          </div>

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_ig') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="data_profile.link_instagram" placeholder="instagram.com/...">
              <i class="icon facebook"></i>
            </div>
          </div>

          <div class="row">
            <div class="label">
              {{ $t('profile_menu_edit_profile_tw') }} 
            </div>
            <div class="field_input">
              <input type="text" v-model="data_profile.link_twitter" placeholder="twitter.com/...">
              <i class="icon twitter"></i>
            </div>
          </div>
  

          <div class="row">
            <div class="label">
              Public email
            </div>
            <div class="field_input">
              <input type="text" v-model="data_profile.link_email" placeholder="Email">
              <i class="icon email"></i>
            </div>
          </div>
  
          <br />
 

          <UiButton
            :name="$t('universal_button_save')"
            :type="'primary'"
            @click="saveSocials"
            :is-loading="isSaving"
            />        

 

        </div>

      </div>

    </div>

    <GrowerSettingsModalChangePassword 
      :user-id="data_profile.id"
      :active="activeModalChangePassword" 
      :old-password="data_profile.password_old"
      @close="closeModalPassword"
      />
  

  </div>

    

</template>

<script setup>

const { t } = useI18n();
const route = useRoute();

const router = useRouter();
const {$api, $tagsUtil, $popup, $ga} = useNuxtApp()

const props = defineProps({
  userId: {
    type: Number
  }
})

// var id = ref(route.params['id']);
 

const activeModalChangePassword = ref(false);
const isSaving = ref(false);
const oldNickName = ref('');

const validURL = function(str, type){
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[@-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  if(!pattern.test(str)) {
    
    return false;
  } else {
    if(str.indexOf(type) < 0){
      
      return false;
    }else{
      return true;
    }

  }
}

const closeModalPassword = function(){
  activeModalChangePassword.value = false;
}

const validate = function(){

  // email validate
  var re = /\S+@\S+\.\S+/;
  if(!re.test(data_profile.value.link_email)){
    $popup.error('Incorrect public email');
    return false;
  }

  /* socials */
  if(data_profile.value.link_facebook?.length && !validURL(data_profile.value.link_facebook,'facebook.com')){
    $popup.error(t('profile_menu_change_social_error_uncorrect_link') + " facebook")
    return false;
  }
  if(data_profile.value.link_instagram?.length && !validURL(data_profile.value.link_instagram,'instagram.com')){
    $popup.error(t('profile_menu_change_social_error_uncorrect_link') + " instagram")
    return false;
  }
  if(data_profile.value.link_youtube?.length && !validURL(data_profile.value.link_youtube,'youtube.com')){
    $popup.error(t('profile_menu_change_social_error_uncorrect_link') + " youtube")
    return false;
  }
  if(data_profile.value.link_twitter?.length && !validURL(data_profile.value.link_twitter,'twitter.com') && !validURL(data_profile.value.link_twitter,'x.com')){
    $popup.error(t('profile_menu_change_social_error_uncorrect_link') + " twitter")
    return false;
  }
    
  return true;
}

const saveSocials = function(){

  if(!validate()){
    return;
  }
  
  isSaving.value = true;  
  $api.putGrowerProfile(data_profile.value.id, data_profile.value)
  .then((res) => {
    isSaving.value = false;
    $popup.success(t('profile_menu_edit_profile_message_save_profile'));
    useAuth().refresh();
  })
  .catch(function (error) {
    isSaving.value = false;
  });

}


const changedCover = function(uploading_id){  
  console.log('loading', uploading_id)
}

const uploadedCover = function(data){  
  data_profile.value.avatar_small = data;
}


const loadData = async function(grower_id) {   
  const response = await $api.getGrowerSettings(grower_id, 'socials')  
  oldNickName.value = response.name;
  return response;  
}


const { pending: is_loading,  data: data_profile } = await useLazyAsyncData('data_profile', async () => await loadData(props.userId))



</script>

<style scoped>

.content_profile {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
}

.modal_user_content .settings_description {
    display: inline-block;
    width: calc(100% - 230px);
    vertical-align: middle;
    margin-right: auto;
}

.modal_user_content .settings_avatar {
    display: inline-block;
    vertical-align: top;
    width: 180px;
    margin-left: 30px;
    position: relative;
}
 
.modal_user_content .ava{
  width: 180px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.modal_user_content .ava .img{
  aspect-ratio: 1/1;
  width: 100%;
  border-radius: 10px;
    border-radius: 100%;
}


.modal_user_content .btn_black {
    background-color: #1B1C1D;
    color: #FFFFFF;
    text-shadow: none;
}

.modal_user_content .save_profile_btn{
    background-color: #21BA45;
    color: #FFFFFF;
    text-shadow: none;
}

.modal_user_content .settings_description .row{
    display: flex;
    width: 100%;
    margin-top: 15px;
}

.modal_user_content .settings_description .label{
    display: inline-block;
    min-width: 280px;
}

.modal_user_content .settings_description .field_input{
    display: inline-block;
    width: 100%;
}

.modal_user_content .settings_description .field_input input{
    width: 100%;
}



@container pb (max-width: 768px) {

  .modal_user_content .ava{
    justify-content: center;
    margin: 0 auto;
  }
  .content_profile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .modal_user_content .settings_avatar{

      margin-left: auto;
      margin-right: auto;
  }

  .modal_user_content .settings_description{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 20px;
  }

  .modal_user_content .settings_description .row{
    display: flex;
    flex-direction: column;
  }

}


</style>
